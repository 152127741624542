<template>
  <v-row class="mr-0" id="step4-right-page" align="center" :justify="currentPage == 4 ? 'start' : 'center'">
    <template v-if="currentPage == 4">
        <v-col cols="6">
            <v-text-field min="0" placeholder="Amount" hide-details="auto" type="number" v-model.number="model.amount" required
            :error-messages="$helpers.errorMsg('name', valid.model.amount, 'Amount')" @input="amountUpdate"
            @focus="$helpers.avoidInputScrollable" @keydown="$helpers.avoidInputArrowKeyPress"></v-text-field>
        </v-col>
        <v-col cols="6" >
            <v-btn block type="button" :outlined="model.paymentFrequency !== 'O'" :depressed="model.paymentFrequency === 'O'" class="mt-3 white--text"
            :color="model.paymentFrequency === 'O' ? 'primary' : 'indigo'">
                Once
            </v-btn>
        </v-col>
        <v-col cols="4" class="d-lg-flex d-block align-center subtitle-2 text-uppercase" >
            Payment method
        </v-col>
    </template>
    <v-col cols="10" v-if="currentPage == 5" class="mt-5">
        <v-row align="center" justify="center">
            <v-col cols="5" class="text-end py-0">Amount :</v-col>
            <v-col cols="5" class="py-0">$ {{ model.amount }}  /  {{ model.paymentFrequency === 'O' ? 'Once' : 'Monthly' }}</v-col>
        </v-row>
    </v-col>
    <v-col :cols="currentPage == 4 ? 4 : 5">
        <v-btn block type="button" :outlined="model.paymentMethod !== 'CC'" :depressed="model.paymentMethod === 'CC'" class="white--text"
        :color="model.paymentMethod === 'CC' ? 'primary' : 'indigo'" @click="payMethodChange('CC');">
            Credit card
        </v-btn>
    </v-col>
    <v-col :cols="currentPage == 4 ? 4 : 5">
        <v-btn block type="button" :outlined="model.paymentMethod !== 'DD'" :depressed="model.paymentMethod === 'DD'" class="white--text"
        :color="model.paymentMethod === 'DD' ? 'primary' : 'indigo'" @click="payMethodChange('DD');">
            Direct Debit
        </v-btn>
    </v-col>
    <template v-if="currentPage == 4">
        <template v-if="$helpers.getCurData('curUserPermissions').indexOf('manual_payment_methods') !== -1">
            <v-col cols="4">
                <v-btn block type="button" :outlined="model.paymentMethod !== 'C'" :depressed="model.paymentMethod === 'C'" class="white--text"
                    :color="model.paymentMethod === 'C' ? 'primary' : 'indigo'" @click="payMethodChange('C');">
                    Cash
                </v-btn>
            </v-col>
            <v-col cols="4">
                <v-btn block type="button" :outlined="model.paymentMethod !== 'CQ'" :depressed="model.paymentMethod === 'CQ'" class="white--text"
                    :color="model.paymentMethod === 'CQ' ? 'primary' : 'indigo'" @click="payMethodChange('CQ');">
                    Cheque
                </v-btn>
            </v-col>
            <v-col cols="4">
                <v-btn block type="button" :outlined="model.paymentMethod !== 'DT'" :depressed="model.paymentMethod === 'DT'" class="white--text"
                    :color="model.paymentMethod === 'DT' ? 'primary' : 'indigo'" @click="payMethodChange('DT');">
                    Direct transfer
                </v-btn>
            </v-col>
        </template>
        <payment-options :v="valid" v-if="['CQ', 'C', 'DT'].indexOf(model.paymentMethod) !== -1" />
    </template>
    <template v-else>
        <v-col cols="7" v-if="['CQ', 'C', 'DT'].indexOf(model.paymentMethod) === -1">
            <v-select @change="cardChange();" dense hide-details="auto"  class="card-existing-badge"
                :disabled="model.newCard"
                :outlined="!model.newCard"
                :filled="model.newCard"
                v-model="model.existingCards"
                :items="donorExistingCardList"
                :class="!model.newCard && model.existingCards ? 'selectable' : ''"
                :color="!model.newCard && model.existingCards ? 'white' : 'primary'"
                :background-color="!model.newCard && model.existingCards ? 'primary' : ''"
                item-text="value"
                item-value="existing_pay_id"
                placeholder="Existing Card/s"
                label=""
                return-object
            >
                <template v-slot:selection="{ item }">
                <span :class="!model.newCard && model.existingCards ? 'text-white' : 'primary--text'" >{{ item.value }}</span>
                </template>
                <template v-slot:append>
                <v-badge bordered color="secondary" :content="existingCardLength"></v-badge>
                </template>
            </v-select>
        </v-col>
        <v-col cols="3" v-if="['CQ', 'C', 'DT'].indexOf(model.paymentMethod) === -1">
            <v-switch dense hide-details class="pt-0"
            v-model="model.newCard"
            label="Use New card"
            color="primary"
            ></v-switch>
        </v-col>
        <v-col cols="12"  class="pt-0" >
            <stripe @updatePayDetails="model={...model, ...$event};" :stripe="stripeEl" :type="model.paymentMethod" :existingCards="model.existingCards"/>
        </v-col>
    </template>
    <v-col v-if="!latestIncentiveLoading && currentIncentive && currentIncentive.optin == 1"
        :cols="['C', 'DT'].indexOf(model.paymentMethod) !== -1 ? '8' : model.paymentMethod == 'CQ' ? '12' : '10'"
        :class="{'pt-0' : ['C', 'DT'].indexOf(model.paymentMethod) === -1}">
      <v-switch dense
          v-model="model.opt_in" :false-value="null" :true-value="currentIncentive.optin_val"
          :label="currentIncentive ? currentIncentive.optin_desc || '' : ''"
          color="primary"
          hide-details
        ></v-switch>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "stepFour",
    props: ["v", "stripeEl"],
    components: {
        Stripe: () => import("@/components/common/stripe"),
        Modal: () => import("@/components/common/modal"),
        PaymentOptions: () => import("@/pages/dashboard/components/PaymentOptions"),
    },
    data: (vm) => ({
        valid: {},
        stripe: null,
        currentPage: vm.currentStep
    }),
    computed: {
        ...mapGetters(["latestIncentiveLoading", "currentIncentive", "currentStep"]),

        donorExistingCardList() {
            const existCardLists = this.$store.getters["donorExistingCardList"];
            if (this.model.paymentMethod === 'DD') {
            return existCardLists.filter(item => item.type === 'au_becs_debit') || [];
            }
            return existCardLists.filter(item => item.type === 'card') || [];
        },
        existingCardLength() {
            return `${this.donorExistingCardList ? this.donorExistingCardList.length : 0}`;
        },
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("setModel", newValue);
            },
        }
    },
    created() {
        this.model = {...{
            existingCards: null,
            cardValidate: false,
            newCard: true,
            paymentFrequency: 'O',
            paymentMethod: 'CC'
        }, ...this.model};
        this.valid = this.v;
    },
    watch: {
        'model.newCard': function (newVal, oldVal) {
            if (newVal) {
                this.$store.commit("updateModel", {cardValidate: false});
                this.model.existingCards = null;
            }
        },
        'model.paymentMethod': function (newVal, oldVal) {
            if (['CQ', 'C', 'DT'].indexOf(newVal) !== -1) {
                this.$store.commit("updateModel", {cardValidate: true});
            }
        },
        'currentStep': {
            handler: function(value) {
                this.currentPage = value;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        cardChange() {
            this.model.cardValidate = true;
            this.model = {...this.model, ...this.model.existingCards}
        },
        payMethodChange(type) {
            this.model.paymentMethod = type;
            this.model.existingCards = this.model.start_date = null;
            this.$store.commit("updateModel", {cardValidate: false});
        },
        amountUpdate() {
            this.$store.dispatch('modelUpdate', this.model);
        }
    }
};
</script>
